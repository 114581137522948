import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 12px;
    line-height: 1.3;
    box-sizing: border-box;
  }
  @media screen and (min-width: 320px) {
    html {
      font-size: calc(12px + 4 * ((100vw - 320px) / 680));
    }
  }
  @media screen and (min-width: 1000px) {
    html {
      font-size: 16px;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body, h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    font-weight: normal;
    color: ${props => props.theme.text};
  }

  body {
    background: ${props => props.theme.background};
    line-height: 1.3;
    font-family: open-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  h1, h2 {
    font-family: mrs-eaves-xl-serif, sans-serif;
    margin-bottom: 1.5rem;
    font-weight: 400;
    &.underlined::after {
        content: '';
        width: 200px;
        margin: 0 auto;
        border-bottom: solid 2px currentColor;
        display: block;
        margin-top: 10px;
    }
  }
  
  h1 {
    font-size: 2.5rem;
    color: ${props => props.theme.blue};
  }
  @media(min-width: 960px) {
    h1 {
      font-size: 3rem;
    }
  }
  h2 {
    font-size: 2.2rem;

  }
  h3 {
    font-weight: bold;
    margin-bottom: .8rem;
    margin-top: 1rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  a {
    color: ${props => props.theme.accent};
    text-decoration: none;
    font-weight: 500;
  }

  ol, ul {
    margin-left: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  main {
    min-height: 100vh;
    background: ${props => props.theme.background};
    position: relative;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  }

  .text-center {
    text-align: center;
  }

  button, .button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
  }
  .contact-icon {
    margin-right: 10px;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .mb-2 {
    margin-bottom: 2rem;
  }
  .mb-3 {
    margin-bottom: 3rem;
  }
  .mt-2 {
    margin-top: 2rem;
  }
  .left-ruled {
    h2 {
      ::after {
        content: '';
        width: 200px;
        border-bottom: solid 1px currentColor;
        display: block;
        margin-top: 15px;
      }
    }
  }
  .center-ruled {
    h2 {
      ::after {
        content: '';
        width: 200px;
        margin: 0 auto;
        border-bottom: solid 1px currentColor;
        display: block;
        margin-top: 15px;
    }
    }
  }
  .accent {
    color: ${props => props.theme.accent};
  }
`
export default GlobalStyle
