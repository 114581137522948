import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import Navbar from "./navbar/index"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "../styles/global"
import Footer from "./footer"

const theme = {
  text: `#464646`,
  accent: `#D9B427`,
  background: `#FFFEFA`,
  background2: `#FCF9ED`,
  background3: `#FCEFC5`,
}

const Layout = ({ children, isHome }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/hlc4wdl.css" />
      </Helmet>
      <GlobalStyle />
      <Navbar isHome={isHome} />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
