import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import MyLogo from "../../images/logo.png"

const StyledLogo = styled.img`
  margin-bottom: 0;
  height: 50px;
  z-index: 300;
  @media (min-width: 992px) {
    height: 80px;
  }
`

const Logo = props => {
  return (
    <Link to="/" style={{ zIndex: 300 }}>
      <StyledLogo src={MyLogo} alt="Mountain View Wealth Logo" />
    </Link>
  )
}
export default Logo
