import styled from "styled-components"

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  ${props =>
    props.skinny &&
    `
    max-width: 920px !important;
  `}
  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1300px) {
    max-width: 1240px;
  }
`
export default Container
