import styled from "styled-components"

const Section = styled.section`
  padding: ${props =>
    props.padding === "none"
      ? 0
      : props.padding === "small"
      ? "1rem 0"
      : props.padding === "medium"
      ? "3rem 0"
      : "5rem 0"};
  ${props => props.topOnly && `padding-bottom: 0`};
  ${props => props.bottomOnly && `padding-top: 0`};
  background: ${props =>
    props.color === "medium"
      ? props.theme.background2
      : props.color === "navy"
      ? props.theme.navy
      : props.theme.background};

  color: ${props => (props.color === "navy" ? props.theme.light : "inherit")};
`

export default Section
