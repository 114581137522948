import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Section, Container } from "./utils"
import styled from "styled-components"

const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 1rem;
  background: ${props => props.theme.background2};
  p,
  a,
  h4 {
    color: #333;
  }
  a.handy-link {
    display: block;
    z-index: 11;
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }
`
const FooterContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
  & > div {
    text-align: center;
    flex: 1 1 200px;
    p {
      margin-bottom: 0.25rem;
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    {
      details: datoCmsContactPage {
        contactOptions {
          text
        }
      }
    }
  `)
  return (
    <Footer>
      <Section color="navy" padding="medium">
        <FooterContainer>
          <div>
            <h3 className="accent">Links</h3>
            <p>
              <a className="footer-link" href="/">
                Home
              </a>
            </p>
            <p>
              <a className="footer-link" href="/about">
                About
              </a>
            </p>
            <p>
              <a className="footer-link" href="/contact">
                Contact
              </a>
            </p>
          </div>
          <div>
            <h3 className="accent">Contact Details</h3>
            {data.details.contactOptions.map(({ text }) => (
              <p key={text}>{text}</p>
            ))}
          </div>
          <div>
            <h3 className="accent">Opening Times:</h3>
            <p>Weekdays: 9:00 - 17:00</p>
            <p>Weekends: Closed</p>
          </div>
        </FooterContainer>
        <Container className="text-center">
          <p>
            <small>
              Copyright {new Date().getFullYear()} Mountain View Wealth
            </small>
          </p>
          <small>
            Site designed and built by{" "}
            <a href="https://kozocreative.com">Kozo Creative</a>
          </small>
        </Container>
        <Container className="text-center"></Container>
      </Section>
    </Footer>
  )
}
