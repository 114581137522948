import React from "react"
import styled from "styled-components"

const Form = styled.form`
  label {
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
  input:not([type="submit"]),
  select,
  textarea {
    background: rgba(255, 255, 255, 0.25);
    font-weight: 500;
    font-size: 0.9rem;
    border: 1px solid #a7a7a7;
    border-radius: 3px;
    padding: 0.4rem;
    width: 100%;
    margin: 0.5rem 0 1rem 0;
    &::placeholder {
      color: #999;
    }
  }
  input[type="submit"] {
    background: ${props => props.theme.accent};
    color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    padding: 0.72rem 1.6rem;
    font-size: 1.1rem;
    cursor: pointer;
    width: 100%;
    letter-spacing: 3px;
  }
`

export default ({ selected, name = "Contact" }) => {
  return (
    <Form
      data-netlify="true"
      action="/contact?success=true"
      method="POST"
      name={name}
    >
      <input type="hidden" name="form-name" value={name} />
      <label htmlFor="Name">Full Name</label>
      <input type="text" name="Name" required />
      <label htmlFor="Email">Email Address</label>
      <input type="text" name="Email" id="Email" required />
      <label htmlFor="interestedIn">I'm interested in...</label>
      <select
        defaultValue={
          selected === "jobseeking"
            ? "Finding a job"
            : selected === "hiring"
            ? "Filling a vacancy"
            : "Asking a question"
        }
        required
        name="interestedIn"
        id="interestedIn"
      >
        <option value="Finding a job">Finding a job</option>
        <option value="Filling a vacancy">Filling a vacancy</option>
        <option value="Asking a question">Asking a question</option>
      </select>
      <label htmlFor="Message">Message</label>
      <textarea name="Message" rows="8"></textarea>
      <div style={{ textAlign: "center" }}>
        <input type="submit" className="button submit" value="SEND" />
      </div>
    </Form>
  )
}
