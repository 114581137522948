import { Link } from "gatsby"
import styled from "styled-components"

const Button = styled(Link)`
  display: inline-block;
  padding: 0.72rem 1.6rem;
  background: ${props => props.theme.accent};
  color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  width: ${props => (props.size === "block" ? `100%` : `auto`)};
  transition: all ease 0.3s;
  :hover {
    background: #e8c205;
    transform: translateY(-3px);
  }
`
export default Button
