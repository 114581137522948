import React, { useState } from "react"
import styled from "styled-components"
import Logo from "./logo"
import Toggle from "./toggle"
import Nav from "./nav"
import { Container } from "../utils/index"

const Header = styled.div`
  position: ${({ isHome }) => (isHome ? `absolute` : `static`)};
  top: 0;
  width: 100%;
  z-index: 500;
  padding: 10px 0;
  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

const Navbar = props => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Header isHome={props.isHome}>
      <Container className="navigation">
        <Logo isHome={props.isHome} />
        <Toggle isHome={props.isHome} handleClick={handleClick} open={open} />
        <Nav id="nav" open={open} setOpen={setOpen} isHome={props.isHome} />
      </Container>
    </Header>
  )
}
export default Navbar
