import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Container } from "../utils"

const Nav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 150px 15px 60px 15px;
  background: white;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.25s ease-in;
  z-index: 10;
  &.open {
    transform: translate3d(0, 0, 0);
  }
  ul {
    margin: -1rem;
    text-align: right;
  }

  @media (min-width: 992px) {
    transform: translate3d(0, 0, 0);
    position: static;
    flex-direction: row;
    width: auto;
    padding: 0;
    background: none;
    ul {
      display: flex;
    }
  }
`
const NavItem = styled.li`
  margin: 1rem;
  list-style: none;
  font-size: 1.8rem;
  @media (min-width: 992px) {
    font-size: 1.1rem;
  }
`
const NavLink = styled(Link)`
  position: relative;
  font-family: mrs-eaves-xl-serif, sans-serif;
  color: ${props => props.theme.text};
  text-decoration: none;
  &.active::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    border-bottom: 3px solid ${props => props.theme.accent};
  }
`

const Navigation = props => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          nav {
            link
            text
          }
        }
      }
    }
  `)
  return (
    <Nav
      open={props.open}
      className={props.open ? `open` : ``}
      isHome={props.isHome}
    >
      <Container>
        <ul>
          {data.site.siteMetadata.nav.map(({ link, text }) => (
            <NavItem key={link}>
              <NavLink
                activeClassName="active"
                partiallyActive={link === "/services" ? true : false}
                to={link}
                isHome={props.isHome}
                onClick={() => props.setOpen(false)}
              >
                {text}
              </NavLink>
            </NavItem>
          ))}
        </ul>
      </Container>
    </Nav>
  )
}
export default Navigation
